import { Tooltip } from 'bootstrap'


document.querySelectorAll('[data-copy-input]').forEach(function(button) {
    let tooltip = new Tooltip(button, {
        container: button,
        title: 'Copy',
        placement: 'top',
    })
    button.onclick = function() {
        let button = this
        let input = document.getElementById(button.dataset.copyInput)

        let tooltipText = button.querySelector('.tooltip-inner')
        navigator.clipboard.writeText(input.value).then(function() {
            tooltipText.innerHTML = 'Copied!'
        }, function(err) {
            tooltipText.innerHTML = 'Clipboard error: ' + err
        })
        tooltip.update()
    }
})
